import React from "react";
import { companyLink, footerIcons, footerLinks, footerNumbers } from "./items";
import { images } from "constants/index";

const Footer = () => {
  return (
    <div className="bg-[#06202C] text-gray-100 font-satoshi font-normal text-base h-auto sm:pb-[30px] xs:pb-[15%] px-3">
      <div className=" py-4">
        <div className="w-full mx-auto flex gap-x-3 justify-center md:mt-6 mt-2 mb-4 md:mb-7 lg:mb-12">
          <p className="flex ">
            <img
              className="z-[1] md:max-w-[100px] max-w-[70px]"
              src={images.fooLogo}
              alt=""
            />
          </p>
          <img
            className="z-[1] md:max-w-[100px] max-w-[40px]"
            src={images.colab}
            alt=""
          />
          <img
            className="z-[1] md:max-w-[100px] max-w-[40px]"
            src={images.hbLogo}
            alt=""
          />
        </div>
      </div>

      <div className="px-[30px] md:px-[30px] lg:px-[50px]  flex flex-col md:flex-row items-center sm:items-start md:items-center justify-center md:justify-between ">
        <div className="flex text-[#D0D0D0] flex-col sm:flex-row items-center w-full gap-[10px] sm:gap-[unset] xs:justify-between md:justify-start md:gap-[32px] ">
          <div className="font-medium">
            © 2023 Travelbay . All rights reserved.
          </div>

          <div className="flex items-center gap-[16px]">
            {footerLinks?.map((data, i) => (
              <div>{data.title}</div>
            ))}
          </div>
        </div>

        <p className="font-medium w-full max-w-[300px] flex justify-center mx-auto text-[#D0D0D0]">
          Supported by Glitch Studios Africa!
        </p>

        {/* <div className="flex gap-[34px] xs:justify-center sm:justify-start items-center xs:mt-[37px] md:mt-0">
          {footerIcons.map((data) => (
            <a
              key={data.id}
              target={"_blank"}
              rel="noreferrer"
              href={data.route}
            >
              <img className="sm:w-[48px] sm:h-[48px]" src={data.icon} />
            </a>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
