import React, { useEffect, useRef, useState } from "react";

const ImageLazyLoader = ({ src, alt, width, height, maxPlaceholderWidth }) => {
  const [imageSrc, setImageSrc] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      threshold: 0.5,
    });

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setImageSrc(src);
        entry.target.src = src;
      }
    });
  };

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div
      style={{
        width: "100%", // Full width
        maxWidth: `${maxPlaceholderWidth}px`, // Maximum width for the placeholder
        margin: "auto",
        paddingBottom: isLoaded ? "0" : "50%", // 2:1 aspect ratio
        position: "relative",
        backgroundColor: isLoaded ? "transparent" : "#ccc", // Transparent background after image is loaded
        zIndex: 1,
      }}
    >
      <img
        ref={imageRef}
        src={imageSrc}
        alt={alt}
        width={width}
        height={height}
        style={{
          display: "block",
          margin: "auto",
        }}
        onLoad={handleImageLoad}
      />
    </div>
  );
};

export default ImageLazyLoader;
