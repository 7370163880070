import React, { useContext, useState } from "react";
import { ReactComponent as Icon } from "../constants/images/tb_icon.svg";
import { ReactComponent as Logo } from "../constants/images/tb_logo.svg";
import { Button } from "./buttons";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoCloseOutline } from "react-icons/io5";
import { FiArrowUpRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import Register from "./modals/register";
import { ModalContext } from "routes/layout";
import { images } from "constants";

const Header = () => {
  const location = useLocation();
  const { showModal, setshowModal, closeModal, toggleModal } =
    useContext(ModalContext);
  const [toggleBtn, setToggleBtn] = useState(false);
  const dashboardLinks = [
    {
      title: "Waitlist",
      link: "/",
    },
    {
      title: "About Us",
      link: "/about_us",
    },
  ];
  return (
    <div className="relative mx-auto w-full ">
      {" "}
      {/* <Register
        showModal={showModal}
        handleBackdropClose={() => {
          setshowModal(false);
        }}
      /> */}
      <img
        src={images.curve}
        className="absolute top-0 z-[55] h-[43px] w-[200vw] text-[#06202C]"
      />
      <p className="h-3" />
      <div className="w-full bar_content whitespace-nowrap absolute z-[56] top-2 z-[55] h-[43px] w-[200vw]">
        <div className="flex items-center gap-[10px] font-bold text-[#06202C] text-[14px]">
          <img src={images.car} />
          Make a deposit of N500,000 ($400) latest Dec 8, 2023 to book your
          slot!
          <img src={images.car1} />
          Make a deposit of N500,000 ($400) latest Dec 8, 2023 to book your
          slot!
          <img src={images.car2} />
        </div>
      </div>
      {/* <header className="bg-white md:h-[100px] h-[64px]  flex items-center justify-between w-full md:px-[100px] px-[24px] ">
        <div className="flex gap-x-[2px] ">
          <Icon />
          <img src="/images/tb_logo.svg" alt="" />
        </div>

        <div className="flex gap-x-5">
          <Link to={"/"} key={"Waitlist"}>
            <div
              className={`text-[#093549] font-medium ${
                location.pathname === "/" && "!text-[#1C9FDA]"
              }`}
            >
              Waitlist
            </div>
          </Link>
          <Link to={"/about_us"} key={"AboutUs"}>
            <div
              className={`text-[#093549] font-medium ${
                location.pathname === "/about_us" && "!text-[#1C9FDA]"
              }`}
            >
              About Us
            </div>
          </Link>
        </div>

        <div className="">
          <Button
            isBlue
            text={"Join the waitlist"}
            className="font-satoshi_variable font-medium rounded-[8px] px-6 py-4 md:block hidden"
            onClick={() => {
              toggleModal();
            }}
          />
          {toggleBtn && (
            <div
              onClick={() => {
                setToggleBtn(false);
              }}
              className={` md:hidden block`}
            >
              <IoCloseOutline size={24} color="#667085" />
            </div>
          )}
          {!toggleBtn && (
            <div
              onClick={() => {
                setToggleBtn(!toggleBtn);
              }}
              className={` md:hidden block`}
            >
              <RxHamburgerMenu size={18} color="#667085" />
            </div>
          )}
        </div>
      </header>
      {toggleBtn && (
        <div className="">
          <div
            onClick={() => {
              setToggleBtn(!toggleBtn);
            }}
            className=" fixed w-[100%] h-[100%] top-0 left-0 z-10 grid place-content-center "
          ></div>
          <div className="absolute md:hidden bg-white top-[64px] left-1/2 -translate-x-1/2 w-full z-20">
            <div className="flex flex-col gap-y-6 px-6">
              <Button
                isBlue
                text={"Join the waitlist"}
                className="font-satoshi_variable font-bold rounded-[8px] px-6 py-4 "
                onClick={() => {
                  toggleModal();
                }}
              />
              <a
                href="https://docs.google.com/forms/d/1h725nQon7seTH7_Bqiq8jxIOp9E4W8eCWPYjJ7gJxe0/edit"
                target="_blank"
              >
                <Button
                  isWhite
                  text={"Participate in our survey"}
                  RightIcon
                  icon={<FiArrowUpRight size={18} color="#1C9FDA" />}
                  className="font-satoshi_variable font-bold rounded-[8px] px-6 py-4 "
                />
              </a>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Header;
