import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "./buttons";
import { FiArrowUpRight } from "react-icons/fi";
import canvas from "../constants/images/canvas.svg";
import canvas2 from "../constants/images/canvas.png";
import { ModalContext } from "routes/layout";
import { images } from "constants";
import TripCard from "./cards/trip_card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { ReactComponent as Icon } from "../constants/images/tb_icon.svg";
import ImageLazyLoader from "./lazyImage";

const Main = () => {
  const { showModal, setshowModal, closeModal, toggleModal } =
    useContext(ModalContext);

  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth || document.documentElement.clientWidth
  );

  const handleJoinWhatsAppGroup = () => {
    // Replace 'YOUR_WHATSAPP_GROUP_LINK' with your actual WhatsApp group link
    const whatsappGroupLink =
      "https://chat.whatsapp.com/JzwgGO8I0doC04ZDvKzw2q";

    // Open the WhatsApp group link in a new tab
    window.open(whatsappGroupLink, "_blank");
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth || document.documentElement.clientWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  console.log(windowWidth);

  return (
    <div className="bg-[#F5F7F9] w-full flex flex-col gap-y-3 mt-7 mb-7 ">
      <div className="bg_design bg-no-repeat bg-cover bg-center flex flex-col max-w-[1440px] mx-auto ">
        <div className="w-full mx-auto flex gap-x-3 justify-center md:mt-6 mt-2 mb-4 md:mb-5 lg:mb-6 ">
          <img
            className="z-[1] w-full md:max-w-[200px] max-w-[120px]"
            src={images.hbLogo}
            alt=""
          />
        </div>

        <div className=" w-full ">
          <p
            onClick={() => {
              handleJoinWhatsAppGroup();
            }}
            className="flex mx-auto p-2 bg-[#ffffff] shadow border rounded-[100px] justify-center w-fit gap-x-1 cursor-pointer"
          >
            <img
              className="z-[1] md:max-w-[100px] max-w-[55px]"
              src={images.travellers}
              alt=""
            />
            <p className="font-satoshi text-[#093549] font-medium text-[14px] md:text-base">
              Join 30+ Travellers
            </p>
          </p>
        </div>

        <div className="flex flex-col gap-y-5 justify-center w-full py-[10px] ">
          <div className="xs:px-[15px] md:px-[50px] relative py-[24px]">
            <p className="font-outfit md:text-[48px] text-[24px] text-[#093549]/90 font-bold text-center w-5/6 mx-auto uppercase">
              Experience{" "}
              <span className="text-[#F97066] font-playfair italic">
                Cape Town
              </span>{" "}
              with the honest bunch this valentine
            </p>

            <p className="absolute top-[1%] left-[5%] z-[1]">
              <img
                className="z-[1] md:max-w-[100px] max-w-[20px]"
                src={images.aeroplane}
                alt=""
              />
            </p>
            <p className="absolute top-[1%] right-[5%] z-[1]">
              <img
                className="z-[1] md:max-w-[100px] max-w-[20px]"
                src={images.love}
                alt=""
              />
            </p>
            <p className="absolute top-[40%] left-[8%] z-[1]">
              <img
                className="z-[1] md:max-w-[100px] max-w-[20px]"
                src={images.flower}
                alt=""
              />
            </p>
            <p className="absolute top-[40%] right-[8%] z-[1]">
              <img
                className="z-[1] md:max-w-[100px] max-w-[20px]"
                src={images.tree}
                alt=""
              />
            </p>
            <p className="absolute bottom-[10%] left-[12%] z-[1]">
              <img
                className="z-[1] md:max-w-[100px] max-w-[20px]"
                src={images.eHeart}
                alt=""
              />
            </p>
            <p className="absolute bottom-[10%] right-[12%] z-[1]">
              <img
                className="z-[1] md:max-w-[100px] max-w-[20px]"
                src={images.rHeart}
                alt=""
              />
            </p>
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="absolute z-2 md:top-[16px] flex justify-center gap-4 mx-auto w-fit w-full ">
          <Button
            isBlue
            text={"View vacation Details > >"}
            className="font-satoshi_variable mx-auto font-bold rounded-[8px] md:px-6 px-3 py-2 md:py-4 max-w-[300px] cursor-pointer z-10"
            onClick={() => {
              toggleModal();
            }}
          />
        </div>
        <div className="w-full max-w-[1200px] mx-auto ">
          <ImageLazyLoader
            src={images.honestBunch} // Replace with your image URL
            alt="The Honest bunch crew"
            maxPlaceholderWidth={1000}
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
