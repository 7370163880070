import { Inputs, SelectInput } from "components/inputs";
import React, { useEffect, useState } from "react";
import { Country, State, City } from "country-state-city";
import {
  AiOutlineCheck,
  AiOutlineCloseCircle,
  AiOutlineRight,
} from "react-icons/ai";

import { ReactComponent as People } from "../../constants/images/people.svg";
import { ReactComponent as Location } from "../../constants/images/locationPin.svg";
import { Button } from "components/buttons";
import { RxDoubleArrowRight } from "react-icons/rx";
import { CiLocationOn } from "react-icons/ci";
import { useMutation } from "@apollo/client";
import { REGISTER_MUTATION } from "GraphQL/Mutations";
import { errorToast, successToast, warningToast } from "components/toast/toast";
import { useParams } from "react-router-dom";
import { images } from "constants";
import ImageLazyLoader from "components/lazyImage";

const Register = ({ showModal, handleBackdropClose }) => {
  const [selectedState, setSelectedState] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { referral_code } = useParams();

  const [location, setLocation] = useState(true);

  const handleJoinWhatsAppGroup = () => {
    // Replace 'YOUR_WHATSAPP_GROUP_LINK' with your actual WhatsApp group link
    const whatsappGroupLink =
      "https://chat.whatsapp.com/JzwgGO8I0doC04ZDvKzw2q";

    // Open the WhatsApp group link in a new tab
    window.open(whatsappGroupLink, "_blank");
  };

  const countryCode = "US";
  const states = State.getStatesOfCountry(countryCode);
  let citiesFromFirstState;
  if (selectedState) {
    citiesFromFirstState = City.getCitiesOfState(countryCode, selectedState);
  }

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [showModal]);

  return (
    <div>
      {showModal && (
        <>
          <div
            id="modal-backdrop"
            className={` fixed w-[100%] h-[100%] top-0 left-0 z-30 grid place-content-center bg-black/40
          `}
            onClick={() => {
              handleBackdropClose();
            }}
          ></div>
          <div className="h-[500px] w-[500px]  border-r-2 border-l-2 border-gray-500 z-40 fixed sm:-top-[50%] bottom-0 left-1/2 sm:translate-y-2/4 -translate-x-2/4 border-dashed bounce"></div>
          <div className="modal_scroll modal_bounce fixed sm:bottom-1/2 bottom-0 left-1/2 sm:translate-y-2/4 -translate-x-2/4 box-border w-full sm:w-[500px] max-h-[calc(100%_-_100px)] overflow-y-auto bg-white py-10 px-6 sm:rounded-lg rounded-t-lg xsm:px-12 z-40  modal_scroll">
            <div className="w-full flex justify-end">
              <AiOutlineCloseCircle
                onClick={() => {
                  handleBackdropClose();
                }}
                className="cursor-pointer"
                size={24}
                color="#667085"
              />
            </div>
            <div className="flex flex-col gap-y-8">
              <div className="w-full flex flex-col gap-4 justify-center w-full">
                <div className="shadow p-1 md:p-2 rounded-full flex justify-between font-satoshi text-[#093549]/90 font-semibold w-full max-w-[500px] mx-auto md:text-base text-[14px] ">
                  <p
                    className={`${
                      location && "bg-[#F5F7F9] border border-black/5"
                    } md:p-4 p-1 rounded-full flex justify-center w-full text-center cursor-pointer`}
                    onClick={() => {
                      setLocation(true);
                    }}
                  >
                    Departing from Nigeria
                  </p>
                  <p
                    className={`${
                      !location && "bg-[#F5F7F9] border border-black/5"
                    } md:p-4 p-1 flex justify-center rounded-full w-full cursor-pointer`}
                    onClick={() => {
                      setLocation(false);
                    }}
                  >
                    Outside Nigeria
                  </p>
                </div>

                <div className="bg-[#F5F7F9] ">
                  <div className="w-full mx-auto ">
                    <ImageLazyLoader
                      src={images.pic} // Replace with your image URL
                      alt="The Honest bunch crew"
                    />
                  </div>

                  <div className="px-4 py-2 flex flex-col gap-y-1">
                    <p className="text-[#0E4F6D] md:text-[24px] text-[20px] font-satoshi font-semibold">
                      Honest Brunch Valentine 2024
                    </p>
                    <p className="flex justify-between">
                      <p className="flex items-center gap-x-1">
                        <Location />
                        <span className="text-[#667085]/70 text-[14px] font-satoshi font-semibold opacity-70">
                          Capetown
                        </span>
                      </p>

                      <p className="flex items-center gap-x-1 font-satoshi">
                        <span className="text-[#667085]/70 text-[14px] font-satoshi font-semibold opacity-70">
                          Per traveler
                        </span>
                        <People />
                      </p>
                    </p>
                    <p className="text-[#344054]/90 font-semibold">
                      FEBUARY 11, 2024 - FEBRUARY 17, 2024
                    </p>
                    {location && (
                      <p className="text-[#475467]/80 font-medium">
                        <span className="text-[#093549]">Trip activities:</span>{" "}
                        Table mountain tour | Cape Town Canal Cruise | Robben
                        Island Tour | Cape Town City Tour | Live recording of
                        Honest Bunch Podcast| Trip Goody Bag.
                      </p>
                    )}
                    {!location && (
                      <p className="text-[#475467]/80 font-medium">
                        <span className="text-[#093549]">Trip activities:</span>{" "}
                        Table mountain tour | Cape Town Canal Cruise | Robben
                        Island Tour | Cape Town City Tour | Live recording of
                        Honest Bunch Podcast| Trip Goody Bag.
                      </p>
                    )}
                    {location && (
                      <p className="text-[#475467]/80 font-medium">
                        <span className="text-[#093549]">Includes:</span> E-visa
                        | Departure Flight |Daily Breakfast | Return Flights |
                        Hotel Stay | Return Airport Pickup
                      </p>
                    )}
                    {!location && (
                      <p className="text-[#475467]/80 font-medium">
                        <span className="text-[#093549]">Includes:</span> Daily
                        Breakfast | Hotel Stay.
                      </p>
                    )}
                  </div>

                  {location && (
                    <div className="bg-[#EDF8FC] p-4">
                      <p className="font-satoshi font-medium text-[#667085]/70 mb-1">
                        TOTAL PRICE
                      </p>
                      <div className="flex justify-between flex-wrap gap-2">
                        <p className="flex flex-col justify-start">
                          <p className="text-[#1C9FDA] text-[24px] font-satoshi font-semibold">
                            N2,290,000{" "}
                          </p>
                          <p className="text-[#0E4F6D] font-medium font-satoshi">
                            (double occupancy)
                          </p>
                        </p>

                        <p className="flex flex-col justify-end">
                          <p className="text-[#1C9FDA] text-[24px] font-satoshi font-semibold">
                            N2,650,000{" "}
                          </p>
                          <p className="text-[#0E4F6D] font-medium font-satoshi">
                            (Single occupancy)
                          </p>
                        </p>
                      </div>
                    </div>
                  )}

                  {!location && (
                    <div className="bg-[#EDF8FC] p-4">
                      <p className="font-satoshi font-medium text-[#667085]/70 mb-1">
                        TOTAL PRICE
                      </p>
                      <div className="flex justify-between flex-wrap gap-2">
                        <p className="flex flex-col justify-start">
                          <p className="text-[#1C9FDA] text-[24px] font-satoshi font-semibold">
                            $1,350
                          </p>
                          <p className="text-[#0E4F6D] font-medium font-satoshi">
                            (double occupancy)
                          </p>
                        </p>

                        <p className="flex flex-col justify-end">
                          <p className="text-[#1C9FDA] text-[24px] font-satoshi font-semibold">
                            $1,650
                          </p>
                          <p className="text-[#0E4F6D] font-medium font-satoshi">
                            (Single occupancy)
                          </p>
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <p className="font-medium text-red-400 mb-2">
                  Deposit {location ? "N500,000" : "$400"} to reserve a spot now
                  !!!
                </p>

                <div>
                  <Button
                    isBlue
                    text={"Reserve a Spot"}
                    className="font-satoshi_variable font-bold rounded-[8px] md:px-6 px-3 py-3 cursor-pointer"
                    RightIcon
                    onClick={() => {
                      handleJoinWhatsAppGroup();
                    }}
                    icon={
                      <div className="flex gap-x-1">
                        <AiOutlineRight /> <AiOutlineRight />
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Register;
