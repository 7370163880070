import Footer from "components/footer";
import Header from "components/header";
import Register from "components/modals/register";
import Toast from "components/toast/toast";
import React, { useState } from "react";

const ModalContext = React.createContext();

const Layout = ({ children }) => {
  const [showModal, setshowModal] = useState(false);

  const closeModal = () => {
    setshowModal(false);
  };
  const toggleModal = () => {
    setshowModal(!showModal);
  };
  const contextValues = {
    showModal,
    setshowModal,
    closeModal,
    toggleModal,
  };

  return (
    <ModalContext.Provider value={contextValues}>
      <div className="w-full flex flex-grow flex-col relative overflow-x-hidden ">
        <header>
          <Header />
          <Register
            showModal={showModal}
            handleBackdropClose={() => {
              setshowModal(false);
            }}
          />
          <Toast />
        </header>
        <section className="w-full">{children}</section>
        <footer className="w-full">
          <Footer />
        </footer>
      </div>
    </ModalContext.Provider>
  );
};

export { Layout, ModalContext };
