import { images } from "../../constants/index";

export const footerIcons = [
  {
    id: 1,
    route: "https://facebook.com/travelbayhq",
    icon: images.fooFb,
  },
  {
    id: 2,
    route: "https://www.instagram.com/travelbayhq/",

    icon: images.fooIg,
  },
  {
    id: 3,
    route: "https://twitter.com/travelbayhq",

    icon: images.fooTwit,
  },
  {
    id: 4,
    route: "https://www.linkedin.com/company/travelbayhq/",
    icon: images.fooLi,
  },
];

export const footerLinks = [
  {
    id: 1,
    title: "Terms",
    route: "/legal/terms",
  },
  {
    id: 2,
    title: "Privacy",
    route: "/legal/policy",
  },
];

export const footerNumbers = {
  phone: [
    {
      id: 1,
      icon: images.phone,
      link: "tel:+1 347 407 5776",
      num: "+1 347 407 5776",
    },
  ],
  mail: {
    icon: images.mail,
    text: "Travelbayonline@gmail.com",
    link: "mailto:travelbayonline@gmail.com",
  },
  address: {
    icon: images.loca,
    text: "220E 23rd St., New York, New York 10010",
  },
};

export const companyLink = [
  {
    id: 1,
    title: "What we offer",
    route: "/dashboard",
  },
  {
    id: 2,
    title: "How it works",
    route: "/dashboard",
  },
  {
    id: 3,
    title: "Exclusive offers",
    route: "/dashboard",
  },
];
