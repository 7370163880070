import fooLi from "./images/fooLi.svg";
import fooIg from "./images/fooIg.svg";
import fooFb from "./images/fooFb.svg";
import phone from "./images/phone.svg";
import loca from "./images/loca.svg";
import mail from "./images/mail.svg";
import fooTwit from "./images/fooTwit.svg";
import fooLogo from "./images/fooLogo.svg";

import curve from "./images/curve.png";
import car from "./images/car.svg";
import car1 from "./images/car1.svg";
import car2 from "./images/car2.svg";
import aeroplane from "./images/aeroplane.svg";
import colab from "./images/colab.svg";
import eHeart from "./images/eHeart.svg";
import flower from "./images/flower.svg";
import hbLogo from "./images/hbLogo.svg";
import love from "./images/love.svg";
import rHeart from "./images/rHeart.svg";
import tag from "./images/tag.svg";
import travellers from "./images/travellers.svg";
import tree from "./images/tree.svg";
import logo from "./images/tb_logo.svg";
import icon from "./images/tb_icon.svg";
import honestBunch from "./images/honestBunch.svg";
import pic from "./images/pic.svg";

export default {
  fooLi,
  fooIg,
  fooFb,
  fooTwit,
  fooLogo,
  phone,
  loca,
  mail,

  curve,
  car,
  car1,
  car2,
  aeroplane,
  colab,
  eHeart,
  flower,
  hbLogo,
  love,
  rHeart,
  tag,
  travellers,
  tree,
  logo,
  icon,
  honestBunch,
  pic,
};
